// ===================================
// editor english lanuage.
//
// @update 2019/01/22
// @author Jesonhu(github)
// @emial jesonhu_web@163.com
// ===================================

export default {
  statusBar: {
    line: 'line',
    columns: 'columns',
    length: 'length'
  }
}